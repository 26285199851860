import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { fetchModelsByPosition } from '~/api/models';
import ModelsScrollContainer from '~/components/Models/ModelsScrollContainer';
import BlockSection from '~/components/legos/BlockSection';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}

const PopularModelsBlock: React.FC<Props> = ({ block, headerType }) => {
  const { filterState, lander } = useFacetFiltering();
  const categoryId =
    Number(
      block.query?.category_id ||
        block.category?.id ||
        filterState?.category?.[0] ||
        lander?.query?.category?.[0] ||
        lander?.category?.id,
    ) || '';

  const brandId =
    Number(
      block.query?.brand_id ||
        lander?.query?.brand?.[0] ||
        filterState?.brand?.[0],
    ) || '';

  const { data: models } = useQuery(['shop-models', block.id], () =>
    fetchModelsByPosition(categoryId, brandId, 8),
  );

  if (!models || models.length === 0) {
    return null;
  }

  return (
    <BlockSection
      block={block}
      headerType={headerType}
      showCta={false}
      id="popular-models"
    >
      <ModelsScrollContainer
        models={models}
        destination={{
          url: block.destination,
          text: block.cta || block.title,
        }}
      />
    </BlockSection>
  );
};

export default PopularModelsBlock;
