import React from 'react';
import ChevronRight from '~/public/static/svg/ChevronRight';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props extends React.HTMLAttributes<HTMLElement> {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
  showCta?: boolean;
}

const BlockSection: React.FC<Props> = ({
  block,
  children,
  headerType: Header = 'h3',
  showCta = true,
  ...props
}) => (
  <section className="container" id={`${block.layout}-${block.id}`} {...props}>
    {block.title && (
      <div className="mb-2 flex items-baseline">
        <Header className="flex text-lg">{block.title}</Header>
        {showCta && block.destination && (
          <a
            href={block.destination}
            className="text-primary-500 ml-4 flex items-center text-sm font-semibold"
          >
            {block.cta || 'See all'}{' '}
            <ChevronRight className="ml-2" height={12} />
          </a>
        )}
      </div>
    )}

    {children}
  </section>
);

export default BlockSection;
